import { useState, useEffect } from "react";
import API_BASE_URL from "./api";
const useGet = ({ url, initialState = [] }) => {
  const [data, setData] = useState(initialState);
  const [error, setError] = useState(false);
  const [isFetching, setFeching] = useState(true);
  const [fetch, setFetch] = useState(false);

  const get = async () => {
    setFeching(true);
    try {
      const { data } = await API_BASE_URL.get(`${url}`);
      setData(data);

      setFeching(false);
    } catch (e) {
      setFeching(false);
      setError(true);
    }
  };

  useEffect(() => {
    get();
  }, [fetch, url]);
  return { data, isFetching, error, fetch, setData, setFetch };
};

export default useGet;
