import React from "react";
import inta from "../../logos/inta.png";
import intac from "../../logos/inta_color.png";
import agypa from "../../logos/agypa.png";
import "./footer.css";
const Footer = () => {
  return (
    <footer>
      <div className="copyright">
        <div className="container-fluid">
          <div className="row center">
            <div className="col">
              <p className="m-auto">
                {"Copyright © "} BioTIC - Portal del Campo{" "}
                {new Date().getFullYear()}
                {"."}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="social">
        {" "}
        <a
          href="https://www.argentina.gob.ar/agricultura-ganaderia-y-pesca"
          className="tweet"
        >
          <img src={agypa}></img>
        </a>
        <a href="https://www.argentina.gob.ar/inta" className="face">
          <img src={inta}></img>
        </a>
      </div>
    </footer>
  );

  {
  }
};
export default Footer;
