import { createTheme } from "@material-ui/core/styles";
import { esES } from "@material-ui/core/locale";
//import { DataGrid } from "@material-ui/x-data-grid";
//import { esES as pickersEs } from "@material-ui/pickers";
import { esES as coreEsES } from "@material-ui/core/locale";
import "./theme.css";

const theme = createTheme(
  {
    palette: {
      primary: { main: "#0072bb", light: "#3290A3" },
      secondary: { main: "#FFFFFF" },
      urgente: { main: "#c62828" },
      normal: { main: "#2e7dd3" },
      importante: { main: "#f9a822" },
      success: { main: "#2e7d33" },
      info: { main: "#2897d4" },
      warning: { main: "#f9a822" },
      danger: { main: "#c62828" },
      gris: { main: "#f2f2f2" },
      azul: { main: "#0072bb" },
    },

    typography: {
      fontFamily: "Encode Sans",
    },

    btn: {
      fontFamily: "Encode Sans",
    },
  },
  esES,
  coreEsES
  //pickersEs
);

theme.responsiveFontSizes = "true";

theme.typography.h1 = {
  fontSize: "4rem",
  lineHeight: 1.167,

  [theme.breakpoints.down("sm")]: {
    fontSize: "3rem",
  },

  [theme.breakpoints.down("xs")]: {
    fontSize: "2.7rem",
  },
};

theme.typography.h2 = {
  fontSize: "3.75rem",
  lineHeight: 1.2,

  [theme.breakpoints.down("sm")]: {
    fontSize: "2.7rem",
  },

  [theme.breakpoints.down("xs")]: {
    fontSize: "2.5rem",
  },
};

theme.typography.h3 = {
  fontSize: "3rem",
  lineHeight: 1.167,

  [theme.breakpoints.down("sm")]: {
    fontSize: "2.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.5rem",
  },
};

theme.typography.h4 = {
  fontSize: "2.125rem",
  lineHeight: 1.235,
  [theme.breakpoints.down("sm")]: {
    fontSize: "2rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.8rem",
  },
};

theme.typography.h5 = {
  fontSize: "1.8rem",
  lineHeight: 1.334,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.5rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "1.1rem",
  },
};

theme.typography.h6 = {
  fontSize: "1.25rem",
  lineHeight: 1.6,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1.1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.9rem",
  },
};

theme.typography.subtitle1 = {
  fontSize: "1rem",
  lineHeight: 1.75,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.8rem",
  },
};

theme.typography.subtitle2 = {
  fontSize: "0.875rem",
  lineHeight: 1.57,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.75rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.65rem",
  },
};

theme.typography.body1 = {
  fontSize: "1.1rem",
  lineHeight: 1.5,
  [theme.breakpoints.down("sm")]: {
    fontSize: "1rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.875rem",
  },
};

theme.typography.body2 = {
  fontSize: "0.9rem",
  lineHeight: 1.43,
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.8rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.7rem",
  },
};

theme.typography.button = {
  fontFamily: "Encode Sans",
  fontWeight: 700,
  fontSize: "1rem",
  lineHeight: 1.75,
  letterSpacing: "0.02857em",
  textTransform: "uppercase",

  [theme.breakpoints.down("sm")]: {
    fontSize: "0.9rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.8rem",
  },
};

theme.typography.caption = {
  fontSize: "0.75rem",
  lineHeight: 1.66,
  letterSpacing: "0.03333em",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.65rem",
  },
  [theme.breakpoints.down("xs")]: {
    fontSize: "0.55rem",
  },
};

export default theme;
