import React from "react";
//import { makeStyles } from "@material-ui/core/styles";
import logoBiotic from "../../logos/logo_biotic_sinfondo.png";
import { ReactComponent as HomeIcon } from "../../logos/homefullcuadrado.svg";
import { ReactComponent as EmailIcon } from "../../logos/signo-de-arroba.svg";
import { ReactComponent as CultivoIcon } from "../../logos/planta2.svg";
import { ReactComponent as GestorIcon } from "../../logos/editar.svg";
import useGet from "../../services/useGet";
import { Link } from "react-router-dom";

function Header(props) {
  // const { data: zonas, isFetching: cargando } = useGet({
  const { data: zonas } = useGet({
    url: "/zonas",
  });
  return (
    <div className="container1">
      <input type="checkbox" name="" id="check"></input>

      <div className="logo-container1">
        <div className="logo">
          <img src={logoBiotic} alt="logo" />
          <span className="span1">Portal del Campo</span>
        </div>
      </div>

      <div className="nav-btn">
        <div className="nav-links">
          <ul>
            <li className="nav-link">
              <span className="icon-button">
                <HomeIcon />
              </span>
              <Link className="a" to="/">
                Inicio
              </Link>
            </li>
            <li className="nav-link">
              <span className="icon-button">
                <CultivoIcon />
              </span>
              <Link className="a" to="#">
                Cultivo<i className="fas fa-caret-down"></i>
              </Link>
              <div className="dropdown">
                <ul>
                  <li className="dropdown-link">
                    <Link className="a" to="#">
                      Citrus<i className="fas fa-caret-down"></i>
                    </Link>
                    <div className="dropdown second">
                      <ul>
                        {Array.isArray(zonas)
                          ? zonas
                              .filter(
                                (filtro) => filtro.cultivo_idcultivo === 1
                              )
                              .map((zona) => {
                                return (
                                  <li
                                    key={zona.idzona}
                                    className="dropdown-link"
                                  >
                                    <Link
                                      className="a"
                                      to={`/cultivo/${zona.idzona}`}
                                    >
                                      {zona.zona}
                                    </Link>
                                  </li>
                                );
                              })
                          : null}
                      </ul>
                    </div>
                  </li>
                  <div className="arrow"></div>
                </ul>
              </div>
            </li>

            <li className="nav-link">
              <span className="icon-button">
                <EmailIcon />
              </span>
              <Link className="a" to="/contacto">
                Contacto
              </Link>
            </li>
            <div className="arrow"></div>
            {props.logueado ? (
              <>
                <li className="nav-link">
                  <span className="icon-button">
                    <GestorIcon />
                  </span>
                  <Link className="a" to="/gestor">
                    Gestor
                  </Link>
                </li>
                <div className="arrow"></div>{" "}
              </>
            ) : (
              <></>
            )}
          </ul>
        </div>

        <div className="log-sign">
          {props.logueado ? (
            <Link
              to="#"
              className="button btn transparent text-info font-weight-bold"
              onClick={() => props.setLogueado(false)}
            >
              Salir
            </Link>
          ) : (
            <Link className="a btn text-info font-weight-bold" to="/login">
              Ingresar
            </Link>
          )}

          <Link className="btn disabled text-info font-weight-bold" to="#">
            Registrarse
          </Link>
        </div>
      </div>

      <div className="hamburger-menu-container">
        <div className="hamburger-menu">
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Header;
