import React, { useState, useEffect, lazy, Suspense } from "react";
//import { createTheme, ThemeProvider } from "@material-ui/core/styles";
//import { esES } from "@material-ui/core/locale";
import "./App.css";
import "./componentes/layout/header.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import Footer from "./componentes/layout/Footer";
import Header from "./componentes/layout/Header";
import Loading from "./componentes/utils/Loading";
import IdleTimer from "./services/IdleTimer";
import withRoot from "./themes/withRoot";

function App() {
  const Multimedia = lazy(() => import("./componentes/multimedia/Multimedia"));
  const AdminAlertas = lazy(() =>
    import("./componentes/admin/alertas/AdminAlerta")
  );
  const Cultivo = lazy(() => import("./componentes/cultivos/Cultivo"));
  const Login = lazy(() => import("./componentes/admin/login/Login"));
  const Gestor = lazy(() => import("./componentes/admin/gestor/Gestor"));
  const Contacto = lazy(() => import("./componentes/contacto/Contacto"));
  const Home = lazy(() => import("./componentes/layout/Home"));
  const [logueado, setLogueado] = useState(false);
  const [login, setLogin] = useState(false);

  useEffect(() => {
    logueado ? setLogin(true) : setLogin(false);
  }, [logueado]);

  const [isTimeout, setIsTimeout] = useState(false);
  useEffect(() => {
    if (logueado) {
      const timer = new IdleTimer({
        timeout: 600, //expire after 10 seconds
        onTimeout: () => {
          setLogueado(false);
          setIsTimeout(true);
        },
        onExpired: () => {
          //do something if expired on load
          setIsTimeout(true);
        },
      });

      return () => {
        timer.cleanUp();
      };
    }
  }, [logueado]);

  const style = {
    paddingTop: "3rem",
    paddingBottom: "6rem",
  };

  return (
    <BrowserRouter>
      <Header setLogueado={setLogueado} logueado={logueado} />
      <Switch>
        <Suspense fallback={<Loading />}>
          <Route path="/login">
            <div style={style}>
              {login ? <Gestor /> : <Login setLogueado={setLogueado} />}
            </div>
          </Route>

          <Route path="/multimedia">
            <div style={style}>
              <Multimedia />
            </div>
          </Route>

          <Route path="/adminalertas">
            <div style={style}>
              <AdminAlertas />
            </div>
          </Route>

          <Route path="/gestor">
            <div style={style}>
              {login ? <Gestor /> : <Login setLogueado={setLogueado} />}
            </div>
          </Route>

          <Route path="/cultivo/:id">
            <div style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
              <Cultivo />
            </div>
          </Route>

          <Route path="/contacto">
            <Contacto />
          </Route>

          <Route exact path="/">
            <div style={{ paddingTop: "3rem", paddingBottom: "3rem" }}>
              <Home />
            </div>
          </Route>
        </Suspense>
      </Switch>
      <Footer />
    </BrowserRouter>
  );
}

export default withRoot(App);
